<template>
  <v-card class="mx-auto" elevation="0">
    <v-card-title class="card-header text-left fixed-card-title2">
      <v-row dense>
        <v-col class="align-center d-flex" cols="10">
          <v-toolbar-title>{{ $t("edit-diagnosis") }}</v-toolbar-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-icon @click="closeDrawer" class="white-icon mr-2">mdi-close</v-icon>
      </v-row>
    </v-card-title>
    <v-card-text class="px-4" >
      <v-row class="pt-5" dense>
        <v-col cols="12">
          <v-autocomplete
              @input="handleAutoCompleteInput"
              v-model="selectedVerifier"
              :items="getICD10Codes"
              :item-text="$i18n.locale === 'en' ? 'shortDescription' : 'shortDescriptionEsp'"
              item-value="lowValue"
              hide-details
              outlined
              dense
              :label="$t('main-category-of-diagnosis')"
              :menu-props="{ maxWidth: '450'}"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
              outlined
              dense
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search_diagnose')"
              hide-details
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col  cols="12">
          <v-data-table
              class="ma-0 pa-0 data-table-outline border-radius0"
              dense
             :loading="loading"
              height="250px"
              :headers="headers"
              fixed-header
              :no-data-text="$t('no-data-available')"
              :items="filteredCategory"
              :items-per-page="-1"
              :footer-props="footerProps"
          >
            <template v-slot:item="{ item }">
              <tr @click="selectedDiagnose(item)">
                <td class="text-left font-bold"><span>{{ item.icd10Code }}</span></td>
                <td class="text-left"><span>{{ item.fullDescription }}</span></td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row dense>
        <span class="font-weight-bold font-size16 color-black ml-1 mb-n2">{{$t("Patient-problem-list")}}</span>
      </v-row>
      <v-row dense>
        <v-col>
          <v-responsive :max-width="$vuetify.breakpoint.lgAndUp ? undefined : '100%'">
            <v-data-table
                :items="detailedUser.userData.patientDiagnosisList"
                item-key="id"
                class="mt-2 data-table-outline border-radius0"
                :headers="patientHeader"
                dense
                :height="tableHeight"
                fixed-header
                :no-data-text="$t('no-data-available')"
                :items-per-page="detailedUser.userData.patientDiagnosisList.length"
                hide-default-footer
                sort-by="id"
                sort-desc
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td class="text-left">
                    <span class="font-bold">{{ item.diagnosisCode }}</span>
                  </td>
                  <td class="text-left">
                    <span>{{ item.diagnoseDescription }}</span>
                  </td>
                  <td>
                    <v-icon class="mdi mdi-delete black-icon" @click="removeDiagnose(item)"></v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-responsive>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import { closeWhite } from '@/assets/index';
import { mapGetters, mapState } from 'vuex';
import { showErrorAlert } from '@/utils/utilities';

export default {
  data () {
    return {
      search: '',
      diagnose: '',
      category: [],
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      closeWhite: closeWhite,
      selectedVerifier: '',
      loading: false,
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.authentication.userData,
      verifiersFromRepo: (state) => state.verifiers.verifiers,
      detailedUser: (state) => state.users.detailedUser,
    }),
    ...mapGetters({
      getICD10Codes: 'verifiers/getICD10Codes',
    }),
    patientHeader () {
      const headers = [
        { text: 'ICD-10', sortable: false, value: 'ICD_10', class: 'color_header' },
        { text: this.$t('description'), sortable: false, value: 'description', class: 'color_header' },
        { text: '', sortable: false, value: '', class: 'color_header' },
      ];
      return headers;
    },
    tableHeight () {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return '300px'; // For medium and smaller screens
      } else if (this.$vuetify.breakpoint.lg) {
        return '300px'; // For large screens
      } else {
        return '500px'; // Default for other screen sizes
      }
    },
    headers () {
      const headers = [
        { text: 'ICD-10', sortable: false, value: 'ICD_10', class: 'color_header' },
        { text: this.$t('diagnose'), sortable: false, value: 'diagnose', class: 'color_header' },
        // { text: ' ', sortable: false },
      ];
      return headers;
    },
    filteredCategory () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (!this.search) {
        return this.category; // Return all items if search is empty
      }
      const keyword = this.search.toLowerCase();
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.category.filter(item => {
        return (
            item.icd10Code.toLowerCase().includes(keyword) ||
            item.fullDescription.toLowerCase().includes(keyword)
        );
      });
    },
  },
  async mounted () {
    this.diagnose = this.detailedUser.userData.patientMetadata.diagnose;
    await this.getVerifiersForDomain();
  },
  watch: {
    searchDiagnose (e) {
      const itemsCopy = this.diagnose;
      if (e === '') {
        this.diagnose = this.verifiersFromRepo;
        return;
      }

      this.diagnose = itemsCopy.filter(item => {
        return item.diagnose.toLowerCase().indexOf(e.toLowerCase()) > -1;
      });
    },
  },
  methods: {
    async selectedDiagnose (item) {
      const filteredItems = this.category.filter(category =>
          category.fullDescription === item.fullDescription || category.icd10Code === item.icd10Code,
      );
      if (filteredItems.length > 0) {
        const selectedICD10Code = filteredItems[0].icd10Code;
        const selectedFullDescription = filteredItems[0].fullDescription;
        const newDiagnosis = `${selectedICD10Code} - ${selectedFullDescription}`;// + ${currentDatetime} - $t('created-by') ${this.userData.firstName + ' ' + this.userData.lastName}`;
        this.selectedVerifier = '';
        this.diagnose = this.diagnose ? `${this.diagnose}\n${newDiagnosis}` : newDiagnosis;
        this.insertPatientDiagnose(selectedICD10Code, selectedFullDescription);
      }
    },
    async insertPatientDiagnose (selectedICD10Code, selectedFullDescription) {
      const body = {
        patientUserId: this.detailedUser.userData.id,
        staffId: this.userData.id,
        hospitalId: this.userData.hospitalAssignedData.id,
        diagnosisCode: selectedICD10Code,
        diagnoseDescription: selectedFullDescription,
      };
      const response = await this.$store.dispatch('patientdiagnosis/createPatientDiagnosis', body);
      if (response.resFlag) {
        this.detailedUser.userData.patientDiagnosisList.push(response.data);
      } else {
        showErrorAlert(this.$t('icdCodeError'));
      }
    },
    closeDrawer () {
      this.selectedVerifier = '';
      this.search = '';
      this.category = [];
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
      this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
    },
    async handleAutoCompleteInput () {
      this.loading = true;
      if (!this.selectedVerifier) {
        return;
      }
      try {
        const body = this.selectedVerifier.toString();
        const response = await this.$store?.dispatch('patientmetadata/getDiagnosesByCategory', body);
        if (response && response.data) {
          this.category = response?.data;
          this.loading = false;
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async getVerifiersForDomain () {
      await this.$store.dispatch('verifiers/getICD10CodeForDomainSpecialization');
    },
    async removeDiagnose (id) {
      await this.$store.dispatch('patientdiagnosis/deletePatientDiagnosis', id.id).then((res) => {
        if (res.resFlag) {
          const elementToDeleteIndex = this.detailedUser.userData.patientDiagnosisList.findIndex((obj) => obj.id === id.id);
          if (elementToDeleteIndex > -1) {
            this.detailedUser.userData.patientDiagnosisList.splice(elementToDeleteIndex, 1);
          }
        } else {
          showErrorAlert(this.$t('icdCodeRemoveError'));
        }
      });
    },
    async getDiagnosesByCategory (item) {
      const response = await this.$store.dispatch('patientmetadata/getDiagnosesByCategory', item);
      if (response && response?.data) {
        this.category = response?.data;
      }
    },
  },
};
</script>
