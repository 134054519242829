<template>
  <v-card class="mx-auto" elevation="0">
    <v-card-title class="text-left card-header" id="fixed-card-title">
      {{$t('edit-monitoring-plan')}}
      <v-spacer></v-spacer>
     <v-icon @click="closeDrawer" class="text-color-white">mdi mdi-close</v-icon>
    </v-card-title>
    <div>
      <v-text-field @input="checkInputChanges" class="px-5 mt-2" outlined dense hide-details v-model="relatedClientMonitoringPlans[0].tpName" label="Title"></v-text-field>
      <v-text-field @input="checkInputChanges" class="px-5 mt-2" outlined dense hide-details v-model="relatedClientMonitoringPlans[0].tpDescription" label="description"></v-text-field>
    </div>
    <v-card elevation="0">
      <div>
        <v-col class="ml-2">
          <div class="d-flex justify-space-between align-center">
            <div class="text-left ml-2 font-weight-bold my-5">{{$t('devices')}}</div>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-btn color="chips" fab class="elevation-5 mt-2 mr-9 text-color-white" height="25" width="25"  v-on="on" v-bind="attrs">+</v-btn>
                </div>
              </template>
              <v-list>
                <v-list-item
                    v-for="(item, index) in medicalDevices"
                    :key="index"
                    @click="assignDevice(item)"
                >
                  <v-list-item-title class="font-size14">
                    <template>
                      <div class="d-flex align-center">
                        <v-img max-width="20" class="mr-3" :src="getDeviceIcons(item)"></v-img>
                        <span class="font-weight-bold color-black font-size13">{{ getDeviceNames(item)}}</span>
                      </div>

                    </template>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <v-expansion-panels  class="px-2" accordion>
            <v-expansion-panel class="elevation-3 mr-3" v-for="(device, index) in relatedClientMonitoringPlans[0].treatmentPlanDevicesList" :key="index">
              <v-expansion-panel-header hide-actions class="d-flex justify-space-between">
                {{ device.deviceTypeTitle }}
                <div>
                   <v-icon @click.stop="deassignDevice(device)" color="chips" class="float-right">mdi mdi-delete</v-icon>
                  <v-icon v-if="device.deviceType!==5"  color="chips" class="float-right">mdi mdi-pencil</v-icon>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="device.deviceType!==5" class="float-left">
                <v-row>
                  <v-select
                      hide-details
                      v-model="device.measurementsCount"
                      :items="selectiontimes"
                      item-text="name"
                      item-value="id"
                      dense
                      class="px-2 z-index1000">

                  </v-select>
                  <v-col v-if="device.measurementsCount >0" cols="6">
                    <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        attach
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="device.time1"
                            label="Time 1"
                            readonly
                            append-icon="mdi mdi-clock"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker v-model="device.time1"></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col v-if="device.measurementsCount > 1" cols="6">
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-left="100"
                        transition="scale-transition"
                        offset-y
                        attach
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="device.time2"
                            label="Time 2"
                            readonly
                            append-icon="mdi mdi-clock"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker  v-model="device.time2"></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="device.measurementsCount > 2"  cols="6">
                    <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        attach
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="device.time3"
                            label="Time 3"
                            readonly
                            append-icon="mdi mdi-clock"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker v-model="device.time3"></v-time-picker>
                    </v-menu>
                    </v-col>
                  <v-col v-if="device.measurementsCount > 3" cols="6">
                    <v-menu
                        v-model="menu4"
                        :close-on-content-click="false"
                        :nudge-left="100"
                        transition="scale-transition"
                        offset-y
                        attach
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="device.time4"
                            label="Time 4"
                            readonly
                            append-icon="mdi mdi-clock"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker  v-model="device.time4"></v-time-picker>
                    </v-menu>
                    </v-col>
                </v-row>
                <v-btn style="color:white;" :disabled="!areTimesPicked(device)" color="#a51717" @click="updateTreatmentPlanDevice(device)">{{$t('save')}}</v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="d-flex align-center justify-space-between">
            <div class="text-left ml-2 font-weight-bold my-5">{{$t('Assessments')}}</div>
            <v-btn class="text-color-white mr-9" fab color="chips" height="25" width="25" @click="openWellnessCard">+</v-btn>
          </div>
          <div class="mx-1 mr-4 mb-4" v-if="openWellness">
            <v-card>
              <div class="pa-2">
                <div style="display: flex; align-items: center;">
                  <img :src="WellnessAssesmentPrimary" height="23px">
                  <div class="font-weight-bold ml-2">{{$t('wellness-assessments')}}</div>
                </div>
              </div>
              <div>
                <v-col cols="12">
                  <v-menu @input="checkSurveyChanges" ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :label="$t('start-date')" v-model="date" prepend-icon="mdi-calendar" readonly hide-details dense outlined v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker  range v-model="date" no-title scrollable >
                      <v-spacer></v-spacer>
                      <v-btn text @click="menu = false">Cancel</v-btn>
                      <v-btn text @click="$refs.menu.save(date)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </div>
              <div class="pa-2">
                <v-select @input="checkSurveyChanges" outlined dense :label="$t('Assessments')" :items="relatedSurveys.data" item-text="templateName" item-value="id" v-model="selectedTemplate"></v-select>
                <v-select class="mt-n3" @input="checkSurveyChanges" outlined dense :label="$t('measurement-period')" :items="surveyPeriods" v-model="selectedPeriod" item-text="shortDescription" item-value="lowValue">
                </v-select>
                <v-card-actions>
                </v-card-actions>
              </div>
            </v-card>
          </div>
          <v-expansion-panels class="px-2 z-index1000" accordion >
            <v-expansion-panel class="elevation-3 mr-3" v-for="(survey, index) in relatedClientMonitoringPlans[0].surveysList" :key="index">
              <v-expansion-panel-header hide-actions>
                <div class="d-flex align-center">
                  <v-icon small class="mr-2"  :style="survey.status? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon>
                 <h6 class="font-size14">
                   {{ survey.templateName }}
                 </h6>
                </div>
                <div>
                  <v-icon @click.stop="DeAssignSurveyFromClientMonitoringPlan(survey)" color="chips" class="float-right">mdi mdi-delete</v-icon>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <h6 class="font-weight-medium text-left">
                  {{survey.templateDescription}}
                </h6>

                <div class="d-flex align-center">
                  <span class="text-left my-5 mr-2 font-weight-medium">{{$t('started')}}</span>
                  <span class="text-left my-n3 font-weight-medium" >{{survey.startDate}}</span>
                </div>
<!--                  {{survey.status}}-->
                <v-select
                    v-model="survey.recurrenceRuleForSurvey"
                    :items="surveyPeriods"
                    item-text="shortDescription"
                    dense
                    item-value="lowValue"
                    outlined
                    hide-details
                    class="mb-2 ml-8"
                >
                </v-select>
                <v-menu :ref="'menu_' + survey.id" v-model="survey.menuOpen" :close-on-content-click="false" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field  :label="$t('start-date')" v-model="survey.startDate" prepend-icon="mdi-calendar" readonly hide-details dense outlined v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker range v-model="survey.startDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeMenu(survey)">{{$t('cancel')}}</v-btn>
                    <v-btn text @click="closeMenu(survey)">OK</v-btn>
                  </v-date-picker>
                </v-menu>

                <v-switch
                    color="primary"
                    @change="handleSurveyStatus(survey)"
                    class="mb-2"
                    hide-details
                    v-model="survey.status"
                >
                </v-switch>
                <div class="float-right mt-n9">
                <v-btn class="ml-auto text-color-white"  @click="updateSurvey(survey)" color="chips">{{$t('save')}}</v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </div>
      <v-card-actions class="v-card-action fixed-card-actions">
        <v-row class="mb-0">
          <v-col cols="6">
            <v-btn
                @click="closeDrawer"
                elevation="0"
                outlined
                width="100%"
                style="text-transform: capitalize;"
            >
              {{$t('cancel')}}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
                class="save-button-style"
                width="100%"
                @click="saveMonitoringPlan"
            >
              {{$t('save')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { closeWhite, closeBlack, arrowRight, wellnessAssessmentPrimary, thermometerBlack, oxymeterBlack, glucoseMeterBlack, bloodPressureBlack, scaleBlack } from '@/assets';
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';

export default {
  data () {
    return {
      date2: this.surveyPeriods,
      thermometer: thermometerBlack,
      oximeter: oxymeterBlack,
      glucose: glucoseMeterBlack,
      bloodpressure: bloodPressureBlack,
      scale: scaleBlack,
      menu5: false,
      arrowRight: arrowRight,
      isEnabled: false,
      showAlertDialog: false,
      time: null,
      hasError: false,
      menu3: false,
      getResponse: null,
      selectiontimes: [
        { name: this.$t('one-time-per-day'), id: 1 },
        { name: this.$t('two-time-per-day'), id: 2 },
        { name: this.$t('three-time-per-day'), id: 3 },
        { name: this.$t('four-time-per-day'), id: 4 },
      ],
      menu4: false,
      hasTitleChanges: false,
      menu2: false,
      changedTitleDescription: false,
      hasSurveyChanges: false,
      systolicMaxExtreme: '',
      deviceAssigned: null,
      menu1: null,
      changesMade: false,
      measurementCountToOptionMap: {
        1: 'onePerDay',
        2: 'twicePerDay',
        3: 'threeTimesPerDay',
        4: 'fourTimesPerDay',
      },
      closeBlack: closeBlack,
      assignedDevice: '',
      CloseWhite: closeWhite,
      treatmentPlanDevicesList: [],
      measurementPeriodOptions: [
        { text: this.$t('one-time-per-day'), value: 'onePerDay' },
        { text: this.$t('two-time-per-day'), value: 'twicePerDay' },
        { text: this.$t('three-time-per-day'), value: 'threeTimesPerDay' },
        { text: this.$t('four-time-per-day'), value: 'fourTimesPerDay' },
      ],
      selectedDevice: '',
      openWellness: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      selectedTemplate: null,
      selectedPeriod: null,
      menu: false,
      WellnessAssesmentPrimary: wellnessAssessmentPrimary,
    };
  },
  computed: {
    ...mapState({
      relatedClientMonitoringPlans: state => state.treatmentplan.relatedClientMonitoringPlans,
      surveyById: (state) => state.mentalhealth.survey,
      surveyPeriods: (state) => state.verifiers.surveyRecurrence,
      relatedSurveys: (state) => state.mentalhealth.relatedSurveys,
      medicalDevices: (state) => state.medicaldevice.medicalDevices,
    }),
    ...mapGetters({
      getMedicalDevices: 'medicaldevice/getMedicalDevices',
    }),

    surveyData () {
      return this.surveyById ? this.surveyById : null;
    },
    areTimesPicked () {
      return (device) => {
        if (device.measurementsCount === 1) {
          return !!device.time1;
        } else if (device.measurementsCount === 2) {
          return !!device.time1 && !!device.time2;
        } else if (device.measurementsCount === 3) {
          return !!device.time1 && !!device.time2 && !!device.time3;
        } else if (device.measurementsCount === 4) {
          return !!device.time1 && !!device.time2 && !!device.time3 && !!device.time4;
        }
        return false;
      };
    },
  },
  async mounted () {
    await this.getListOfTemplates();
    await this.getPossibleDevices();
    await this.getSurveyRecurrencePeriod();
  },
  methods: {
    getDeviceIcons (item) {
      switch (item.deviceType) {
        case 1:
          return this.thermometer;
        case 2:
          return this.oximeter;
        case 3:
          return this.bloodpressure;
        case 5:
          return this.scale;
        case 6:
          return this.glucose;
        case 7:
          return this.glucose;
      }
    },
    getDeviceNames (device) {
      switch (device.deviceType) {
        case 5 :
          return this.$t('weight-scale');
        case 3:
          return this.$t('blood-pressure');
        case 2:
          return this.$t('oximeter');
        case 1:
          return this.$t('thermometer');
        case 6 :
          return this.$t('glucose-meter');
      }
    },
    closeMenu (recurrence) {
      recurrence.menuOpen = false;
    },
    handleSurveyStatus (recurrence) {
      const body = {
        id: recurrence.id,
        StartDate: recurrence.startDate,
        EndDate: recurrence.endDate,
        templateName: recurrence.templateName,
        templateDescription: recurrence.templateDescription,
        recurrenceRuleForSurvey: recurrence.recurrenceRuleForSurvey,
        status: recurrence.status === false ? 0 : 1,
      };

      this.$store.dispatch('mentalhealth/updateSurvey', body).then(res => {
        if (res.resFlag) {
          showSuccessAlert('Assessment Updated ! ');
        } else {
          showSuccessAlert(res.msg);
        }
      });
    },
    updateSurvey (item) {
      let startDate = null;
      let endDate = null;

      if (Array.isArray(item.startDate)) {
        startDate = item.startDate[0];
        endDate = item.startDate[1] ? item.startDate[1] : null;
      } else if (item.startDate !== null) {
        startDate = item.startDate;
      }

      const body = {
        id: item.id,
        StartDate: startDate,
        EndDate: endDate,
        templateName: item.templateName,
        templateDescription: item.templateDescription,
        recurrenceRuleForSurvey: item.recurrenceRuleForSurvey,
        status: 1,
      };
      this.$store.dispatch('mentalhealth/updateSurvey', body).then(res => {
        if (res.resFlag) {
          showSuccessAlert('Assessment Updated!');
        } else {
          showSuccessAlert(res.msg);
        }
      });
      this.clickedEdit = false;
      this.selectedPeriodEdit = null;
      this.date2 = null;
    },
    async updateTreatmentPlanDevice (device) {
      const { measurementsCount, treatmentPlanId, deviceType, id, time1, time2, time3, time4 } = device;
      const body = {
        treatmentPlanId,
        deviceType,
        measurementPeriod: 'D',
        id,
        measurementsCount,
        time1: measurementsCount >= 1 ? time1 : null,
        time2: measurementsCount >= 2 ? time2 : null,
        time3: measurementsCount >= 3 ? time3 : null,
        time4: measurementsCount === 4 ? time4 : null,
      };
      await this.$store.dispatch('treatmentplan/updateTreatmentPlanDevice', body).then(res => {
        showSuccessAlert('Devices Updated');
        this.$store.dispatch('treatmentplan/relatedClientMonitoringPlans');
      });
    },
    assignDevice (data) {
      const monitoringData = this.relatedClientMonitoringPlans[0];
      const body = {
        treatmentPlanId: monitoringData.id,
        deviceType: data.deviceType,
        measurementPeriod: 'D',
        measurementsCount: 1,
        time1: '8:00',
        time2: null,
        time3: null,
        time4: null,
        manufacturerType: data.manufacturerId,
      };
      this.$store.dispatch('treatmentplan/assignDevice', body).then(res => {
        this.$store.dispatch('treatmentplan/relatedClientMonitoringPlans');
      });
    },
    getPossibleDevices () {
       this.$store.dispatch('medicaldevice/getPossibleDevices');
    },
    deassignDevice (device) {
      const body = {
        treatmentPlanDeviceId: device.id,
      };
      this.$store.dispatch('treatmentplan/deassignDevice', body.treatmentPlanDeviceId).then(res => {
          showSuccessAlert('Device Deleted');

          this.$store.dispatch('treatmentplan/relatedClientMonitoringPlans');
      });
    },
    getRelatedClientMonitoringPlans () {
      this.$store.dispatch('treatmentplan/relatedClientMonitoringPlans');
    },
    DeAssignSurveyFromClientMonitoringPlan (data) {
        const body = {
          surveyId: data.id,
        };
      this.$store.dispatch('mentalhealth/DeAssignSurveyFromClientMonitoringPlan', body).then(res => {
        showSuccessAlert('Assessment Deleted');
        this.getRelatedClientMonitoringPlans();
      });
    },
    async assignSurveyTemplateToClientMonitoringPlan () {
      let startDate = null;

      if (Array.isArray(this.date)) {
        startDate = this.date[0];
      } else {
        startDate = this.date;
      }

      const body = {
        SurveyTemplateId: this.selectedTemplate,
        StartDate: startDate,
        EndDate: null,
        RecurrenceRuleForSurvey: this.selectedPeriod,
        OrganizationId: this.relatedClientMonitoringPlans[0].organizationId,
        TreatmentPlanId: this.relatedClientMonitoringPlans[0].id,
      };

      try {
        const res = await this.$store.dispatch('mentalhealth/assignSurveyTemplateToClientMonitoringPlan', body);
        if (res.resFlag) {
          this.getRelatedClientMonitoringPlans();
          this.menu = null;
          this.date = null;
          this.hasError = false;
          this.selectedTemplate = null;
          this.selectedPeriod = null;
        } else {
          showErrorAlert('Survey Not Added');
        }
      } catch (error) {
        this.hasError = true;
      }
    },
    getListOfTemplates () {
      this.$store.dispatch('mentalhealth/getListOfTemplates');
    },
    updateTitleDescription () {
      const body = {
        tpName: this.relatedClientMonitoringPlans[0].tpName,
        tpDescription: this.relatedClientMonitoringPlans[0].tpDescription,
        treatmentPlanId: this.relatedClientMonitoringPlans[0].id,
      };
      this.$store.dispatch('treatmentplan/updateTitleDescription', body)
          .then(res => {
        if (res.resFlag) {
          this.getRelatedClientMonitoringPlans();
          this.menu = null;
          this.date = null;
          this.showAlertDialog = true;
          this.selectedTemplate = null;
          this.selectedPeriod = null;
        } else {
          showErrorAlert('Title not added');
        }
      });
    },
    checkInputChanges () {
      this.hasTitleChanges = true;
    },
    checkSurveyChanges () {
      this.hasSurveyChanges = true;
    },
       async saveMonitoringPlan () {
          if (this.hasTitleChanges) {
            this.updateTitleDescription();
            // this.closeDrawer();
          }
          if (this.hasSurveyChanges) {
          await this.assignSurveyTemplateToClientMonitoringPlan();
          }
          if (this.hasError) {
            showErrorAlert('Operation Denied!');
            return;
          }
         showSuccessAlert('Success');
         this.closeDrawer();
         this.hasTitleChanges = false;
         this.hasSurveyChanges = false;
    },
    closeDrawer () {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
      this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
      this.openWellness = false;
    },
    async openWellnessCard () {
      this.openWellness = !this.openWellness;
      await this.getSurveyRecurrencePeriod();
    },
    async getSurveyRecurrencePeriod () {
      await this.$store.dispatch('verifiers/getSurveyRecurrencePeriod');
    },
  },
};
</script>
