<template>
  <v-card
      elevation="0"
      v-if="surveyData"
  >
    <v-card-title class="card-header fixed-card-title2">
      <v-row dense>
        <v-col cols="6" class="d-flex align-center justify-start">
          <v-toolbar-title>{{$t('edit-assessment')}}</v-toolbar-title>
        </v-col>
        <v-col cols="6" class="d-flex align-center justify-end">
          <v-switch
              class="my-n1"
              color="light-green"
              hide-details
              dense
              @change="disableSurvey"
              v-model="surveyData.status"
              icon-size="10"
          ></v-switch>
        </v-col>
      </v-row>
    </v-card-title>
    <!-- Role -->
    <v-card-text class="mb-16">
      <v-form
          ref="form"
          v-model="valid"
      >
        <!--Assessment Name-->
        <v-text-field
            class="my-3"
            outlined
            v-model="surveyData.templateName"
            dense
            hide-details
            :label="$t('assessment-name')"
            required
        ></v-text-field>
        <!--Description-->
        <v-textarea
            class="no-resize my-3"
            outlined
            multi-line
            :rows="5"
            dense
            v-model="surveyData.templateDescription"
            hide-details
            :label="$t('description')"
            required
            no-resize
        ></v-textarea>
        <!--Questions Section-->
        <v-row class="my-1 mx-0">
          <span class="font-size-small-bold-text">{{ $t('questions') }}</span>
        </v-row>
        <!--Add Question Button-->
        <v-row class="my-1 mx-0 d-flex justify-center align-center">
          <v-btn
              class="white-color capitalize-text"
              elevation="0"
              color="primary2"
              width="100%"
              @click="createQuestion"
          >
            <v-icon dense color="white" class="mdi mdi-plus"></v-icon>
            {{$t('question')}}
          </v-btn>
        </v-row>
        <div v-if="showSubmitButton" class="pa-2">
<!--        <v-row dense>-->
<!--          <v-col cols="12">-->
<!--            <v-text-field-->
<!--                v-if="showSubmitButton"-->
<!--                class="my-2"-->
<!--                outlined-->
<!--                v-model="newQuestion"-->
<!--                dense-->
<!--                hide-details-->
<!--                :label="$t('question')"-->
<!--            ></v-text-field>-->
<!--          </v-col>-->
<!--        </v-row>-->
        <!--        VTORO-->
<!--        <div v-if="showSubmitButton">-->
<!--          <v-row dense class="mx-0">-->
<!--            <v-autocomplete-->
<!--                v-model="selectedVerifier"-->
<!--                :items="getSurveyTypesForForm"-->
<!--                :item-text="$i18n.locale === 'en' ? 'shortDescription' : 'shortDescriptionEsp'"-->
<!--                item-value="lowValue"-->
<!--                hide-details-->
<!--                outlined-->
<!--                dense-->
<!--                :label="$t('type')"-->
<!--            ></v-autocomplete>-->
<!--          </v-row>-->
<!--          <div class="align-center d-flex justify-start my-2" v-if="showSubmitButton">-->
<!--            <v-simple-checkbox-->
<!--                color="primary"-->
<!--                dense-->
<!--                v-model="isMandatory"-->
<!--            >-->
<!--            </v-simple-checkbox>-->
<!--            <div v-if="showSubmitButton">-->
<!--              <span class="font-weight-bold color-black">{{$t('is-mandatory')}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
          <v-card v-if="showSubmitButton" class="pa-2">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                    v-if="showSubmitButton"
                    class="my-2"
                    outlined
                    v-model="newQuestion"
                    dense
                    hide-details
                    :label="$t('question')"
                ></v-text-field>
              </v-col>
            </v-row>
            <div v-if="showSubmitButton">
              <v-row dense class="mx-0" >
                <v-autocomplete
                    v-model="selectedVerifier"
                    :items="filterSurveyTypes"
                    :item-text="$i18n.locale === 'en' ? 'shortDescription' : 'shortDescriptionEsp'"
                    item-value="lowValue"
                    hide-details
                    outlined
                    dense
                    :label="$t('type')"
                ></v-autocomplete>
              </v-row>
              <v-text-field
                  class="my-3"
                  outlined
                  v-if="showSubmitButton && selectedVerifier !==3 "
                  v-model="newAnswer"
                  dense
                  hide-details
                  :label="$t('answer')"
              ></v-text-field>
              <v-col cols="12" class="text-left d-flex font-weight-bold py-2 px-0 color-black" v-if="showSubmitButton">
                <v-simple-checkbox
                    color="primary"
                    dense
                    v-model="isMandatory"
                >
                </v-simple-checkbox>
                <span>{{$t('is-mandatory')}}</span>
              </v-col>
              <v-btn
                  v-if="showSubmitButton"
                  color="primary2"
                  elevation="0"
                  :disabled="(selectedVerifier === 3 && newQuestion === '') || (selectedVerifier !== 3 && (newQuestion === '' || newAnswer === '' || selectedVerifier === -1))"
                  width="100%"
                  class="mt-2 capitalize-text white-color"
                  @click="submitQuestion"
              >
                {{$t('create-question')}}
              </v-btn>
            </div>
          </v-card>

        </div>

        <div class="margin-top20">
          <v-expansion-panels :value="panel" class="display-block">
            <draggable :list="surveyById.questionsListWithAnswers" @end="(event) => endQuestionsDrag(event, surveyById.questionsListWithAnswers)">
              <v-expansion-panel v-model="panel" dense v-for="(question, index) in surveyById.questionsListWithAnswers" :key="index">
                <v-expansion-panel-header class="custom-header py-0 my-0" >
                  <v-row dense>
                    <v-col >
                    </v-col>
                    <v-col class="pa-0 d-flex align-center">
                      <v-icon color="main_black" class="mr-2 mb-2" @click.stop="deleteQuestion(question, index)">mdi-trash-can-outline</v-icon>
                    </v-col>
                  </v-row>
                  <v-col >
                    <template v-slot:actions class>
                      <v-icon dense >mdi-chevron-down</v-icon>
                    </template>
                    <v-avatar size="22px" color="chips" class="mr-1">
                      <span class="text-white">{{index+1}}</span>
                    </v-avatar>
                    <span class="font-weight-medium font-size15">{{ question.question }}</span>
                  </v-col>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mx-n4">
                  <!--Question-->
<!--                  Tuka-->
                  <v-text-field
                      class="my-3 mt-n1"
                      outlined
                      dense
                      clear-icon="mdi-backspace-outline"
                      hide-details
                      v-model="question.question"
                      :label="$t('question')"
                      required
                  >
                    <template v-slot:append>
                      <v-icon
                          color="main_black"
                          @click.stop="editQuestionTitle(question)"
                      >
                        mdi-content-save
                      </v-icon>
                    </template>
                  </v-text-field>
                  <!--                    TRETO-->
                  <v-row dense class="mx-0 pb-3">
                    <v-autocomplete
                        auto-select-first
                        v-model="question.answerTypeId"
                        :items="filterSurveyTypes"
                        :item-text="$i18n.locale === 'en' ? 'shortDescription' : 'shortDescriptionEsp'"
                        item-value="lowValue"
                        hide-details
                        outlined
                        class="z-index1000"
                        dense
                        @change="updateQuestion(question)"
                        :label="$t('type')"
                    ></v-autocomplete>
                  </v-row>
                  <div v-if="question.answerTypeTitle !== 'Custom text' && question.answerTypeTitle !== null && question.answerTypeTitle !== undefined">
                    <v-row><span class="d-flex align-center justify-end ma-3 font-weight-bold font-size16" v-if="question.answerTypeId!==3">{{$t('answers')}}</span>
                    <span v-if="surveyById.mentalHealthCategoryId === 27"  class="mt-3 ml-auto mr-4 font-size13  font-weight-bold">{{$t('score-points')}}</span>
                    </v-row>
                    <draggable :list="question.offeredAnswersList" tag="tbody" @end="(event) => endDrag(event, question.offeredAnswersList)">
                      <v-row dense v-for="(answer, answerIndex) in question.offeredAnswersList" :key="answerIndex">
                        <div style="width:430px;" v-if="question.answerTypeId!==3 ">
                          <v-text-field
                              class="my-2 ml-2"
                              :class="{
                                  'mr-2': surveyById.mentalHealthCategoryId === 27,
                                  'customPadding': surveyById.mentalHealthCategoryId === 27
                                }"
                              outlined
                              dense
                              hide-details
                              v-model="answer.possibleAnswer"
                          >
                            <template v-slot:append>
                              <div class="margin-left70">
                                <v-row dense class="mt-0">
                                  <v-tooltip top color="primary" style="z-index:9999;">
                                    <template v-slot:activator="{ on }">
                                      <v-simple-checkbox
                                          color="primary"
                                          dense
                                          v-model="answer.triggerNotification"
                                          @click="editAnswer(question, answer, answerIndex)"
                                          v-on="on"
                                      ></v-simple-checkbox>
                                    </template>
                                    <span>Trigger Notification</span>
                                  </v-tooltip>
                                  <v-icon
                                      color="main_black"
                                      @click="editAnswer(question, answer, answerIndex)"
                                  >
                                    mdi-content-save
                                  </v-icon>
                                  <v-icon
                                      color="main_black"
                                      @click="deleteItem(answer, index)"
                                  >
                                    mdi-trash-can-outline
                                  </v-icon>
                                </v-row>
                              </div>

                            </template>
                          </v-text-field>
                        </div>
                        <div class="ml-auto mt-n16">
                          <div class="align-center ml-auto" v-if="surveyById.mentalHealthCategoryId===27">
                            <div style="width:50px;">
                              <v-text-field class="mr-2 mt-4 " v-model="answer.scorePoints" :value="answer.scorePoints" hide-details dense @blur="editAnswerRow(answer)" outlined></v-text-field>
                            </div>
                          </div>
                        </div>
                      </v-row>
                    </draggable>
                  </div>
                  <v-text-field
                      class="my-3"
                      outlined
                      v-if="!showFirstButton"
                      v-model="newAnswer"
                      dense
                      hide-details
                      :label="$t('answer')"
                  ></v-text-field>
                  <v-row dense class="mx-n1 mt-3" >
                    <v-col v-if="!showFirstButton">
                      <v-btn
                          class="capitalize-text text-color-white"
                          elevation="0"
                          color="primary2"
                          width="100%"
                          @click="createAnswer(index, question)"
                      >
                        <v-icon>mdi-plus</v-icon>
                        <span class="mx-2 text-transform-none font-size15">{{$t('add-answer')}}</span>
                      </v-btn>
                    </v-col>
                    <v-col v-if="showFirstButton && question.answerTypeId!==3">
                      <v-btn
                          elevation="0"
                          color="primary2"
                          width="100%"
                          class="capitalize-text text-color-white"
                          @click="addAnswer"
                      >
                        <v-icon>mdi-plus</v-icon>
                        <span class="mx-2 text-transform-none font-size15">{{ $t('add-new-answer') }}</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </draggable>
          </v-expansion-panels>
          </div>
      </v-form>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row dense class="mb-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="cancel-button-style capitalize-text"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              class="save-button-style"
              @click="updateSurveyTemplate"
          >
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import draggable from 'vuedraggable';
export default {
	name: 'DashboardCoreAppBar',
	components: {
		draggable,
	},
	data: () => ({
    panel: null,
    assessmentName: '',
    selectedCustom: null,
    currentPanel: null,
    surveyId: null,
		isMandatory: false,
    showQuestions: true,
		selected: [],
		checkbox: false,
		showFirstButton: true,
		selectedVerifier: -1,
		showSubmitButton: false,
		newQuestion: '',
		newAnswer: [],
		valid: true,
		notifyAnswer: false,
		questions: [
			{
				name: '',
				type: 3,
				options: [],
			},
		],
		questionsList: [],
		description: '',
		questionOptions: [],
		selectedQuestionType: '',
		dragOptions: {
			handle: '.drag-btn',
		},
	}),
	computed: {
		...mapState({
			surveyById: (state) => state.mentalhealth.survey,
			userData: (state) => state.authentication.userData,
		}),
		...mapGetters({
			// hospitalId: 'authentication/getHospitalId',
			getMentalHealthCategoryForForm: 'verifiers/getMentalHealthCategoryForForm',
			getSurveyTypesForForm: 'verifiers/getSurveyTypesForForm',
		}),
    testHeight () {
      if (this.$vuetify.breakpoint.width <= 1280 && this.$vuetify.breakpoint.height <= 800) {
        return '350';
      }
      return '550';
    },
		surveyData () {
			// return this.surveyById ? this.surveyById : null;
      return this.surveyById ? this.surveyById : {};
		},
		hospitalId () {
			const hospitalId = this.userData.hospitalAssignedData.id;
			return hospitalId;
		},
    filterSurveyTypes () {
      const res = this.getSurveyTypesForForm.filter(item => item.lowValue !== 3);
      return res;
    },
	},
  methods: {
    editQuestionTitle (item) {
      const body = {
        id: item.id,
        question: item.question,
        answerTypeId: item.answerTypeId,
        orderNumber: item.orderNumber,
        isMandatory: item.isMandatory,
      };
      // console.log('Body : ', body);
      this.$store.dispatch('mentalhealth/updateQuestion', body);
    },
    editAnswerRow (item) {
      const object = {
        stQuestionId: item.stQuestionId,
        possibleAnswer: item.possibleAnswer,
        orderNumber: item.orderNumber,
        id: item.id,
        triggerNotification: item.triggerNotification,
        scorePoints: item.scorePoints === '' ? null : item.scorePoints,
      };
      // Dispatch the update API call
      this.$store.dispatch('mentalhealth/updateAnswer', object);
    },
    endDrag (event, items) {
      const itemId = items[event.newDraggableIndex].id;
      const orderId = event.newDraggableIndex + 1;

      // Update the order of items in the items array
      items.forEach((item, index) => {
        item.orderNumber = index + 1;
      });

      const updatedAnswer = {
        stOfferedAnswerId: itemId,
        changedOrderNumberValue: orderId,
      };

      // Call the API only once for the moved item
      this.$store.dispatch('mentalhealth/stofferAnswerReorder', updatedAnswer);
    },
    endQuestionsDrag (event, items) {
      const questionId = items[event.newDraggableIndex].id;
      const orderId = event.newDraggableIndex + 1;

      const updatedQuestion = {
        StQuestionId: questionId,
        ChangedOrderNumberValue: orderId,
      };
      this.$store.dispatch('mentalhealth/stquestionReorder', updatedQuestion);
    },
		// updateTriggerNotification (question, answerIndex) {
		// 	const triggerNotificationValue = this.question.offeredAnswersList[answerIndex].triggerNotification;
		// 	this.$set(question.offeredAnswersList[answerIndex], 'triggerNotification', !triggerNotificationValue);
		// },
		createQuestion () {
			this.showSubmitButton = true;
			// this.questions.push({
			// 	type: 3,
			// 	name: '',
			// 	options: [],
			// 	id: null,
			// });
    },
		addAnswer () {
			this.showFirstButton = false;
      this.showQuestions = false;
			// this.questions.push({
			// 	type: 3,
			// 	name: '',
			// 	options: [],
			// 	id: null,
			// });
		},
		createAnswer (index, question) {
			var createAnswer = {
				stQuestionId: question.id, // Use the id of the current question
				possibleAnswer: this.newAnswer,
				orderNumber: question.offeredAnswersList.length + 1,
				triggerNotification: this.notifyAnswer,
			};
			this.$store.dispatch('mentalhealth/createAnswer', createAnswer).then((res) => {
				if (res.data.triggerNotification) {
					this.selected.push(
						res.data.id,
					);
				}
				this.showFirstButton = true;
				this.newAnswer = '';
				this.$store.dispatch('mentalhealth/getSurveysById', this.surveyData.id);
			});
      this.newAnswer = '';
		},
		submitQuestion () {
			var object = {
				surveyTemplateId: this.surveyData.id,
				question: this.newQuestion,
				answerTypeId: this.selectedVerifier,
				orderNumber: this.questions.length,
				isMandatory: this.isMandatory,
			};

			this.$store.dispatch('mentalhealth/createQuestion', object).then((res) => {
				this.questions.push({
					type: res.answerTypeId,
					name: res.question,
					options: [],
					id: res.id,
				});
        this.panel = this.surveyById.questionsListWithAnswers.length;
        this.showSubmitButton = false;
				this.newQuestion = '';
				this.selectedVerifier = -1;
				this.isMandatory = false;
				this.$store.dispatch('mentalhealth/getSurveysById', this.surveyData.id);
				this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', this.hospitalId);
			});
		},

		updateQuestion (question) {
			const updatedQuestion = {
				question: question.question,
				answerTypeId: question.answerTypeId,
				orderNumber: question.orderNumber,
				isMandatory: question.isMandatory,
				id: question.id,
			};
      this.selectedCustom = updatedQuestion.answerTypeId;
			this.$store.dispatch('mentalhealth/updateQuestion', updatedQuestion);
		},
    // updateQuestion (question, index) {
    //   if (question.id !== null && question.id !== undefined) {
    //     const object = {
    //       question: question.name,
    //       answerTypeId: question.type,
    //       orderNumber: index + 1,
    //       isMandatory: true,
    //       id: question.id,
    //     };
    //     this.$store.dispatch('mentalhealth/updateQuestion', object);
    //   }
    // },
		editAnswer (question, answer) {
			const index = question.offeredAnswersList.indexOf(answer);
			if (index === -1) {
				return;
			}
			const updatedAnswer = {
				stQuestionId: question.id,
				possibleAnswer: answer.possibleAnswer,
				orderNumber: index + 1,
				triggerNotification: answer.triggerNotification, // Use the triggerNotification property of the answer
				id: answer.id,
        scorePoints: answer.scorePoints === '' ? null : answer.scorePoints,
			};
			this.$store.dispatch('mentalhealth/updateAnswer', updatedAnswer).then(() => {
				this.$store.dispatch('mentalhealth/getSurveysById', this.surveyData.id);
			});
		},
    disableSurvey () {
      const object = {
        templateName: this.assessmentName,
        mentalHealthCategoryId: this.mentalHealthCategoryId,
        templateDescription: this.description,
        id: this.surveyData.id,
        status: this.surveyData.status ? 1 : 0,
      };
       this.$store.dispatch('mentalhealth/updateSurveyTemplate', object).then(res => {
        // Handle success or any other actions you need to take
        this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', this.hospitalId);
        this.surveyId = res.id;
      });
    },
		async updateSurveyTemplate () {
			this.assessmentName = this.surveyData.templateName;
			this.description = this.surveyData.templateDescription;

			const selectedMentalHealthCategory = this.getMentalHealthCategoryForForm.find(category =>
				category.longDescription === this.surveyData.mentalHealthCategoryTitle,
			);
			this.mentalHealthCategoryId = selectedMentalHealthCategory ? selectedMentalHealthCategory.lowValue : null;
			const object = {
				templateName: this.assessmentName,
				mentalHealthCategoryId: this.mentalHealthCategoryId,
				templateDescription: this.description,
				id: this.surveyData.id,
        status: this.surveyData.status ? 1 : 0,
			};

			await this.$store.dispatch('mentalhealth/updateSurveyTemplate', object).then(res => {
				// Handle success or any other actions you need to take
				this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', this.hospitalId);
				this.surveyId = res.id;
			});
			this.closeDrawer();
		},
		// deleteOption (questionOption) {
		// 	this.questionOptions = this.questionOptions.filter(
		// 		(option) => option !== questionOption,
		// 	);
		// },
		deleteQuestion (item) {
			this.$store.dispatch('mentalhealth/deleteQuestion', item.id).then(() => {
				this.$store.dispatch('mentalhealth/getSurveysById', this.surveyData.id);
			});
		},
    deleteItem (item, index) {
      this.$store.dispatch('mentalhealth/deleteAnswer', item.id).then(res => {
        this.$store.dispatch('mentalhealth/getSurveysById', this.surveyById.id);
      });
    },
		closeDrawer () {
      // clean up the current select survey template from the store
      this.$store.commit('mentalhealth/CLEAR_CURRENT_SURVEY_TEMPLATE', null);
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
			this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
		},
	},
	watch: {
		checkbox (val) {
			this.notifyAnswer = val;
		},
	},

};
</script>
