<template>
  <v-card
      class="mx-auto"
      elevation="0"
      v-if="getUserById"
  >
    <v-card-title class="card-header text-left fixed-card-title2">
      <v-row dense>
        <v-col cols="10" class="d-flex align-center justify-start">
          <v-toolbar-title>{{$t('edit-staff')}}</v-toolbar-title>
        </v-col>
        <v-col cols="2" class="d-flex align-center justify-end">
            <v-switch
                class="pa-0 ma-0 me-1"
                color="light-green"
                hide-details="auto"
                dense
                v-model="userData.status"
                icon-size="10"
                @change="saveStaffStatus"
            ></v-switch>
        </v-col>
      </v-row>
    </v-card-title>
    <!-- Role -->
    <v-card-text class="pa-4">
      <!-- Edit Picture -->
      <v-form
          ref="form"
          v-model="valid"
      >
        <!--				<v-row class="my-1 pt-2">-->
        <!--					<v-col cols="3">-->
        <!--						<v-img width="80" height="80" :src="uploadPhoto"></v-img>-->
        <!--					</v-col>-->
        <!--					<v-col cols="5" class="text-left my-3">-->
        <!--						<span style="font-weight: bold; font-size: 17px; display: block;">{{$t('profile-picture')}}</span>-->
        <!--						<span style="display: block;">{{$t('profile-picture-text')}}</span>-->
        <!--					</v-col>-->
        <!--					<v-col cols="4" class="d-flex pl-6 mt-3">-->
        <!--						<v-file-input-->
        <!--							:label="$t('upload')"-->
        <!--							class="bold-label"-->
        <!--							dense-->
        <!--							multiple-->
        <!--							outlined-->
        <!--							prepend-inner-icon="mdi-upload"-->
        <!--							prepend-icon=""-->
        <!--						>-->
        <!--						</v-file-input>-->
        <!--					</v-col>-->
        <!--				</v-row>-->
        <!-- Divider -->
        <!-- Data -->
        <v-row dense>
          <v-col
              class="d-flex pa-1"
              cols="12"
              sm="6"
          >
            <v-text-field
                outlined
                :rules="[v => !!v || $t('onlyLetters')]"
                dense
                :label="$t('name')"
                v-model="userData.firstName"
                hide-details="auto"
                @change="buttonEnable"
                required
            ></v-text-field>
          </v-col>
          <v-col
              class="d-flex pa-1"
              cols="12"
              sm="6"
          >
            <v-text-field
                outlined
                :rules="[v => !!v || $t('onlyLetters')]"
                dense
                :label="$t('surname')"
                v-model="userData.lastName"
                required
                hide-details="auto"
                @change="buttonEnable"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
              class="d-flex pa-1"
              cols="12"
              sm="12"
          >
            <v-text-field
                outlined
                dense
                hide-details="auto"
                :rules="[v => !!v || $t('itemRequired')]"
                :label="$t('phone')"
                v-model="userData.phone"
                @input="formatPhoneNumber"
                required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense v-if="!addressIsValid">
          <v-col
                 class="d-flex pa-1"
                 cols="12"
                 sm="12"
          >
            <v-autocomplete
                :label="$t('time-zone')"
                dense
                hide-details="auto"
                :rules="[v => !!v || $t('timeZoneSelect')]"
                v-model="userData.userTimezone"
                item-text="timeZoneId"
                item-value="timeZoneId"
                @change="buttonEnable"
                :items="getTimeZone"
                outlined
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- Speialization -->
        <v-row dense v-if="userData.roleId === 3" class="d-flex pa-1">
          <template>
            <v-autocomplete
                outlined
                :rules="[v => !!v || $t('itemRequired')]"
                dense
                hide-details
                :label="$t('specializations')"
                multiple
                v-model="specialization"
                :item-text="$i18n.locale === 'en' ? 'longDescription' : 'longDescriptionEsp'"
                item-value="lowValue"
                :items="getSpecializationsForForm"
            >
              <template v-slot:selection="{ item }">
                <v-chip
                    label
                    class="white-icon mt-2 mb-1"
                    color="primary"
                >
                  {{ $i18n.locale === 'en' ? item.longDescription : item.longDescriptionEsp}}
                </v-chip>
              </template>
            </v-autocomplete>
          </template>
        </v-row>
      </v-form>
      <!-- Divider -->
      <v-row dense>
        <v-card class="elevation-3 pa-3 mx-1 mt-3" width="500">
            <div class="d-flex align-center">
              <img class="staffView-office-contact-person-icon"  :src="PatientBlack" />
              <span class="small-font mt-1">{{$t('contact-person')}} : {{userData.hospitalsAssignedData[0].contactPerson}}
                </span>
            </div>
          <div class="text-left d-flex flex-column">
            <span class="small-font">{{$t("officeInfo")}}</span>
            <span class="small-font mt-1">{{ userData.hospitalsAssignedData[0].hospitalName }}</span>
            <span class="small-font mt-1">{{userData.hospitalsAssignedData[0].medicalInstitutionData.address}}</span>
            <span  class="small-font" v-if="userData.hospitalsAssignedData[0].contactPerson">
              <div class="mt-1">
            <v-icon color="primary" size="17">mdi-phone</v-icon>
            <span class="color-primary mr-2 mt-1">{{userData.phone}}</span>
              </div>

            </span>
          </div>
        </v-card>
      </v-row>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row class="mb-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="capitalize-text cancel-button-style"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              :disabled="userData.firstName === '' || userData.lastName ==='' || userData.phone === ''"
              class="save-button-style"
              @click="saveStaff()"
          >
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
    patientBlack,
	uploadPhoto,
} from '@/assets';
import { mapGetters, mapState } from 'vuex';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';

export default {
	name: 'DashboardCoreAppBar',
	components: {},
	data: () => ({
		valid: true,
		uploadPhoto: uploadPhoto,
		editedStaff: {},
    PatientBlack: patientBlack,
		userObject: {},
		specialization: [],
		tempSepecializations: [],
		specializationIds: [],
    addressIsValid: true,

  }),
	computed: {
		...mapState({
			authUserData: (state) => state.authentication.userData,
      // userData: (state) => state.viewDetailsDrawer,

		}),
		...mapGetters({
			getUserById: 'users/getUserById',
			roleName: 'authentication/getRole',
			// getHospital: 'authentication/getHospital',
			getTimeZone: 'verifiers/getTimeZone',
			getSpecializationsForForm: 'verifiers/getSpecializationsForForm',
		}),
		// userStatus: {
		// 	get () {
		// 		return this.userObject.status;
		// 	},
		// 	set (newVal) {
		// 		if (newVal !== this.userData.status) {
		// 			this.userObject.status = newVal;
		// 			this.saveStaffStatus();
		// 		}
		// 	},
		// },
		userData () {
			return this.getUserById;
		},
		getHospital: {
			get () {
				return this.userData?.hospitalAssignedData;
			},
		},
	},
	methods: {
    formatPhoneNumber (val) {
      this.userData.phone = new AsYouType().input(val);
      return this.userData.phone;
    },
		loadTimeZone () {
			this.$store.dispatch('verifiers/getTimeZones').then(() => {
			});
		},
    loadForms () {
      this.$store.dispatch('verifiers/getVerifiersForDomainSpecialization').then(() => {
      });
    },
		loadData () {
			this.loadTimeZone();
		},
		buttonEnable () {
			this.valid = false;
		},
		saveStaffStatus: async function () {
			const body = {
				id: this.userData.id,
				status: this.userData.status ? 1 : 0,
			};

			await this.$store.dispatch('users/setStatus', {
				id: body.id,
				status: body.status,
			});
      await this.getStaff();
		},
		saveStaff: async function () {
			if (this.$refs.form.validate()) {
				const body = {
					id: this.userData.id,
					firstName: this.userData.firstName,
					lastName: this.userData.lastName,
					phone: this.userData.phone,
					email: this.userData.email,
					address: this.userData.address,
					zip: this.userData.zip,
					city: this.userData.city,
					state: this.userData.state,
					country: this.userData.country,
					addressLatitude: this.userData.addressLatitude,
					addressLongitude: this.userData.addressLongitude,
					username: this.userData.email,
					roleId: this.userData.roleId,
					hospitalId: this.getHospital?.id,
					userTimezone: this.userData.userTimezone,
				};
				await this.$store.dispatch('users/updateUser', body).then(() => {
					if (this.userData.roleId === 3) {
						var assSpecialization = this.specialization.filter(x => !this.tempSepecializations.includes(x));
						var deassSpecialization = this.tempSepecializations.filter(x => !this.specialization.includes(x));
						var specializationIds = [];

						assSpecialization.forEach(id => {
							specializationIds.push(id);
						});
						if (specializationIds.length > 0) {
							this.$store.dispatch('doctormetadata/assignDoctorToSpetialization', {
								doctorUserId: this.userObject.id,
								spetializationTypeIdsList: specializationIds,
							}).then(res => {
								this.$emit('updated', res);
							});
						}
							var deassSpecializationIds = [];
							deassSpecialization.forEach(id => {
								deassSpecializationIds.push(id);
							});

							this.$store.dispatch('doctormetadata/deAssignDoctorToSpetialization', {
								doctorUserId: this.userObject.id,
								spetializationTypeIdsList: deassSpecializationIds,
							}).then(res => {
								this.$emit('updated', res);
							});
							this.tempSepecializations = [];
						}
					this.getStaff();
				});
			} else {
				this.$refs.form.validate();
			}
		},
		// getStaff () {
		// 	this.$store
		// 		.dispatch('hospitals/getRelatedMedicalStaff', this.getHospital.id).then(() => {
		// 			this.closeDrawer();
		// 		});
		// },
		async getStaff () {
			// this.loading = true;
			if (this.roleName === 'ORGANIZATION_ADMIN') {
				const organizationId = this.authUserData.organizationId;
				await this.$store
					.dispatch('medicalInstitutions/getRelatedOrganizationMedicalStaff', organizationId)
					.then(() => {
						// this.loading = false;
						this.closeDrawer();
					});
			} else if (this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
				const hospitalId = this.authUserData.hospitalAssignedData.id;
				await this.$store
					.dispatch('hospitals/getRelatedMedicalStaff', hospitalId)
					.then(() => {
						// this.loading = false;
						this.closeDrawer();
					});
			}
		},
		closeDrawer () {
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
			this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
		},
    checkValidationOfTimeZone () {
      if (this.getHospital.addressLatitude !== 0.01 && this.getHospital.addressLongitude !== 0.01 && this.getHospital.addressLongitude !== null && this.getHospital.addressLatitude !== null) {
        this.addressIsValid = true;

        const latLng = {
          lat: this.getHospital.addressLatitude,
          lng: this.getHospital.addressLongitude,
        };

        this.$store.dispatch(
            'googleAPI/getTimeZone',
            latLng,
        ).then(response => {
          this.userData.userTimezone = response.zoneName;
        });
      } else {
        // TODO There is bad address show time zoene lookup
        this.addressIsValid = false;
        this.userData.addressLatitude = 0.01;
        this.userData.addressLongitude = 0.01;
      }
    },
  },
  mounted () {
    this.loadData();
    this.loadForms();
    this.checkValidationOfTimeZone();
    this.userObject = { ...this.getUserById }; // create a shallow copy of the object
    this.userObject.status = this.userObject.status === 1;
    // Clear specialization arrays before updating
    if (this.userData.roleId === 3) {
      this.specialization = [];
      this.tempSepecializations = [];
      if (this.userObject.doctorMetadataReadDto.spetializationData && this.userData.roleId === 3) {
        const uniqueSpecializations = new Set(); // Create a Set to store unique values
        this.userObject.doctorMetadataReadDto.spetializationData.forEach(item => {
          uniqueSpecializations.add(item.spetializationId); // Add each spetializationId to the Set
        });
        this.specialization = Array.from(uniqueSpecializations); // Convert the Set back to an array
        this.specialization.forEach(id => {
          this.tempSepecializations.push(id);
        });
      }
    }
    if (this.userData.status === -1) {
      this.userData.status = 0;
    }
  },
	watch: {
		'userObject.phone': function (newVal) {
			if (newVal !== undefined) {
				if (!isValidPhoneNumber(newVal)) {
					this.phoneRules = [false];
				} else {
					this.phoneRules = [true];
				}
				this.userObject.phone = new AsYouType().input(newVal);
			}
		},
    // getUserById () {
    //   this.userObject = { ...this.getUserById }; // create a shallow copy of the object
    //   this.userObject.status = this.userObject.status === 1;
    //   // Clear specialization arrays before updating
    //   if (this.userData.roleId === 3) {
    //     this.specialization = [];
    //     this.tempSepecializations = [];
    //     if (this.userObject.doctorMetadataReadDto.spetializationData) {
    //       const uniqueSpecializations = new Set(); // Create a Set to store unique values
    //       this.userObject.doctorMetadataReadDto.spetializationData.forEach(item => {
    //         uniqueSpecializations.add(item.spetializationId); // Add each spetializationId to the Set
    //       });
    //       this.specialization = Array.from(uniqueSpecializations); // Convert the Set back to an array
    //       this.specialization.forEach(id => {
    //         this.tempSepecializations.push(id);
    //       });
    //     }
    //   }
    // },
	},

};
</script>
